import React, { FC } from 'react';
import _ from 'lodash';
import DropDownChild from './DropDownChild';
import { NavModelItem } from '@grafana/data';

interface Props {
  link: NavModelItem;
  onHeaderClick?: () => void;
}

const SideMenuDropDown: FC<Props> = (props) => {
  const { link, onHeaderClick } = props;
  let childrenLinks: NavModelItem[] = [];
  if (link.children) {
    childrenLinks = _.filter(link.children, (item) => !item.hideFromMenu);
  }

  let linkText = link.text;
  linkText = linkText.replace('Create', '新規作成');
  linkText = linkText.replace('Dashboards', 'ダッシュボード');
  linkText = linkText.replace('Explore', 'エクスプローラ');
  linkText = linkText.replace('Alerting', 'アラート');
  linkText = linkText.replace('Configuration', '設定');
  linkText = linkText.replace('Server Admin', 'サーバ管理');
  linkText = linkText.replace('Help', 'ヘルプ');

  return (
    <ul className="dropdown-menu dropdown-menu--sidemenu" role="menu">
      <li className="side-menu-header">
        <a className="side-menu-header-link" href={link.url} onClick={onHeaderClick}>
          <span className="sidemenu-item-text">{linkText}</span>
        </a>
      </li>
      {childrenLinks.map((child, index) => {
        return <DropDownChild child={child} key={`${child.url}-${index}`} />;
      })}
    </ul>
  );
};

export default SideMenuDropDown;
