import { locale, scaledUnits, simpleCountUnit, toFixedUnit, ValueFormatCategory, stringFormater } from './valueFormats';
import {
  dateTimeAsIso,
  dateTimeAsIsoNoDateIfToday,
  dateTimeAsMs,
  dateTimeAsMsNoDateIfToday,
  dateTimeAsUS,
  dateTimeAsUSNoDateIfToday,
  getDateTimeAsLocalFormat,
  getDateTimeAsLocalFormatNoDateIfToday,
  dateTimeFromNow,
  toClockMilliseconds,
  toClockSeconds,
  toDays,
  toDurationInDaysHoursMinutesSeconds,
  toDurationInHoursMinutesSeconds,
  toDurationInMilliseconds,
  toDurationInSeconds,
  toHours,
  toMicroSeconds,
  toMilliSeconds,
  toMinutes,
  toNanoSeconds,
  toSeconds,
  toTimeTicks,
  dateTimeSystemFormatter,
} from './dateTimeFormatters';
import { toHex, sci, toHex0x, toPercent, toPercentUnit } from './arithmeticFormatters';
import { binaryPrefix, currency, SIPrefix } from './symbolFormatters';

export const getCategories = (): ValueFormatCategory[] => [
  {
    name: '分類外',
    formats: [
      { name: 'none', id: 'none', fn: toFixedUnit('') },
      { name: 'String', id: 'string', fn: stringFormater },
      {
        name: 'short',
        id: 'short',
        fn: scaledUnits(1000, ['', ' K', ' Mil', ' Bil', ' Tri', ' Quadr', ' Quint', ' Sext', ' Sept']),
      },
      { name: 'パーセント (0-100)', id: 'percent', fn: toPercent },
      { name: 'パーセント (0.0-1.0)', id: 'percentunit', fn: toPercentUnit },
      { name: '湿度 (%H)', id: 'humidity', fn: toFixedUnit('%H') },
      { name: 'デシベル (dB)', id: 'dB', fn: toFixedUnit('dB') },
      { name: '16進数 (0x)', id: 'hex0x', fn: toHex0x },
      { name: '16進数', id: 'hex', fn: toHex },
      { name: '指数表記', id: 'sci', fn: sci },
      { name: 'ロケール形式', id: 'locale', fn: locale },
      { name: 'ピクセル(px)', id: 'pixel', fn: toFixedUnit('px') },
      { name: '歪み (ε)', id: 'epsilon', fn: toFixedUnit('ε') },
    ],
  },
  {
    name: '加速度',
    formats: [
      { name: 'メートル/秒² (m/sec²)', id: 'accMS2', fn: toFixedUnit('m/sec²') },
      { name: 'フィート/秒² (f/sec²)', id: 'accFS2', fn: toFixedUnit('f/sec²') },
      { name: '重力単位 (g)', id: 'accG', fn: toFixedUnit('g') },
      { name: 'ピークデータ (g・sec)', id: 'gsec', fn: toFixedUnit('g・sec') },
    ],
  },
  {
    name: '角度',
    formats: [
      { name: '度 (°)', id: 'degree', fn: toFixedUnit('°') },
      { name: 'ラジアン (rad)', id: 'radian', fn: toFixedUnit('rad') },
      { name: 'グラジアン (grad)', id: 'grad', fn: toFixedUnit('grad') },
      { name: '分 (arcmin)', id: 'arcmin', fn: toFixedUnit('arcmin') },
      { name: '秒 (arcsec)', id: 'arcsec', fn: toFixedUnit('arcsec') },
    ],
  },
  {
    name: '広さ',
    formats: [
      { name: '平方メートル (m²)', id: 'areaM2', fn: toFixedUnit('m²') },
      { name: '平方フィート (ft²)', id: 'areaF2', fn: toFixedUnit('ft²') },
      { name: '平方マイル (mi²)', id: 'areaMI2', fn: toFixedUnit('mi²') },
    ],
  },
  {
    name: 'コンピュータ',
    formats: [
      { name: 'FLOP/s', id: 'flops', fn: SIPrefix('FLOP/s') },
      { name: 'MFLOP/s', id: 'mflops', fn: SIPrefix('FLOP/s', 2) },
      { name: 'GFLOP/s', id: 'gflops', fn: SIPrefix('FLOP/s', 3) },
      { name: 'TFLOP/s', id: 'tflops', fn: SIPrefix('FLOP/s', 4) },
      { name: 'PFLOP/s', id: 'pflops', fn: SIPrefix('FLOP/s', 5) },
      { name: 'EFLOP/s', id: 'eflops', fn: SIPrefix('FLOP/s', 6) },
      { name: 'ZFLOP/s', id: 'zflops', fn: SIPrefix('FLOP/s', 7) },
      { name: 'YFLOP/s', id: 'yflops', fn: SIPrefix('FLOP/s', 8) },
    ],
  },
  {
    name: '濃度',
    formats: [
      { name: '百万分率 (ppm)', id: 'ppm', fn: toFixedUnit('ppm') },
      { name: '十億分率 (ppb)', id: 'conppb', fn: toFixedUnit('ppb') },
      { name: 'ナノグラム/立方メートル (ng/m³)', id: 'conngm3', fn: toFixedUnit('ng/m³') },
      { name: 'ナノグラム/立方メートル (ng/Nm³)', id: 'conngNm3', fn: toFixedUnit('ng/Nm³') },
      { name: 'マイクログラム/立方メートル (μg/m³)', id: 'conμgm3', fn: toFixedUnit('μg/m³') },
      { name: 'マイクログラム/立方メートル (μg/Nm³)', id: 'conμgNm3', fn: toFixedUnit('μg/Nm³') },
      { name: 'ミリグラム/立方メートル (mg/m³)', id: 'conmgm3', fn: toFixedUnit('mg/m³') },
      { name: 'ミリグラム/立方メートル (mg/Nm³)', id: 'conmgNm3', fn: toFixedUnit('mg/Nm³') },
      { name: 'グラム/立方メートル  (g/m³)', id: 'congm3', fn: toFixedUnit('g/m³') },
      { name: 'グラム/立方メートル (g/Nm³)', id: 'congNm3', fn: toFixedUnit('g/Nm³') },
      { name: 'ミリグラム/デシリットル (mg/dL)', id: 'conmgdL', fn: toFixedUnit('mg/dL') },
      { name: 'ミリモル/リットル (mmol/L)', id: 'conmmolL', fn: toFixedUnit('mmol/L') },
    ],
  },
  {
    name: '通貨',
    formats: [
      { name: 'ドル ($)', id: 'currencyUSD', fn: currency('$') },
      { name: 'ポンド (£)', id: 'currencyGBP', fn: currency('£') },
      { name: 'ユーロ (€)', id: 'currencyEUR', fn: currency('€') },
      { name: '日本円 (¥)', id: 'currencyJPY', fn: currency('¥') },
      { name: 'ルーブル (₽)', id: 'currencyRUB', fn: currency('₽') },
      { name: 'フリヴニャ (₴)', id: 'currencyUAH', fn: currency('₴') },
      { name: 'レアル (R$)', id: 'currencyBRL', fn: currency('R$') },
      { name: 'デンマーク クローネ (kr)', id: 'currencyDKK', fn: currency('kr', true) },
      { name: 'アイスランド クローナ (kr)', id: 'currencyISK', fn: currency('kr', true) },
      { name: 'ノルウェー クローネ (kr)', id: 'currencyNOK', fn: currency('kr', true) },
      { name: 'スウェーデン クローナ (kr)', id: 'currencySEK', fn: currency('kr', true) },
      { name: 'チェコ コルナ (czk)', id: 'currencyCZK', fn: currency('czk') },
      { name: 'スイス フラン (CHF)', id: 'currencyCHF', fn: currency('CHF') },
      { name: 'ポーランド ズウォティ (PLN)', id: 'currencyPLN', fn: currency('PLN') },
      { name: 'ビットコイン (฿)', id: 'currencyBTC', fn: currency('฿') },
      { name: 'ミリビットコイン (mBTC)', id: 'currencymBTC', fn: currency('mBTC') },
      { name: 'マイクロビットコイン (μBTC)', id: 'currencyμBTC', fn: currency('μBTC') },
      { name: '南アフリカ ランド (R)', id: 'currencyZAR', fn: currency('R') },
      { name: 'インドルピー (₹)', id: 'currencyINR', fn: currency('₹') },
      { name: '韓国ウォン (₩)', id: 'currencyKRW', fn: currency('₩') },
      { name: 'インド ルピー (Rp)', id: 'currencyIDR', fn: currency('Rp') },
      { name: 'フィリピン ペソ (PHP)', id: 'currencyPHP', fn: currency('PHP') },
      { name: 'ベトナム ドン (VND)', id: 'currencyVND', fn: currency('đ', true) },
    ],
  },
  {
    name: 'データ',
    formats: [
      { name: 'バイト [B](IEC)', id: 'bytes', fn: binaryPrefix('B') },
      { name: 'バイト [B](SI)', id: 'decbytes', fn: SIPrefix('B') },
      { name: 'ビット [b](IEC)', id: 'bits', fn: binaryPrefix('b') },
      { name: 'ビット [b](SI)', id: 'decbits', fn: SIPrefix('b') },
      { name: 'キビバイト [KiB]', id: 'kbytes', fn: binaryPrefix('B', 1) },
      { name: 'キロバイト [KB]', id: 'deckbytes', fn: SIPrefix('B', 1) },
      { name: 'メビバイト [MiB]', id: 'mbytes', fn: binaryPrefix('B', 2) },
      { name: 'メガバイト [MB]', id: 'decmbytes', fn: SIPrefix('B', 2) },
      { name: 'ギビバイト [GiB]', id: 'gbytes', fn: binaryPrefix('B', 3) },
      { name: 'ギガバイト [GB]', id: 'decgbytes', fn: SIPrefix('B', 3) },
      { name: 'テビバイト [TiB]', id: 'tbytes', fn: binaryPrefix('B', 4) },
      { name: 'テラバイト [TB]', id: 'dectbytes', fn: SIPrefix('B', 4) },
      { name: 'ペビバイト [PiB]', id: 'pbytes', fn: binaryPrefix('B', 5) },
      { name: 'ペタバイト [PB]', id: 'decpbytes', fn: SIPrefix('B', 5) },
    ],
  },
  {
    name: 'データレート',
    formats: [
      { name: 'パケット/秒 [p/s]', id: 'pps', fn: SIPrefix('p/s') },
      { name: 'バイト/秒 [B/s](IEC)', id: 'binBps', fn: binaryPrefix('B/s') },
      { name: 'バイト/秒 [B/s](SI)', id: 'Bps', fn: SIPrefix('B/s') },
      { name: 'ビット/秒 [b/s](IEC)', id: 'binbps', fn: binaryPrefix('b/s') },
      { name: 'ビット/秒 [b/s](SI)', id: 'bps', fn: SIPrefix('b/s') },
      { name: 'キビバイト/秒 [KiB/s]', id: 'KiBs', fn: binaryPrefix('B/s', 1) },
      { name: 'キビビット/秒 [Kib/s]', id: 'Kibits', fn: binaryPrefix('b/s', 1) },
      { name: 'キロバイト/秒 [KB/s]', id: 'KBs', fn: SIPrefix('B/s', 1) },
      { name: 'キロビット/秒 [Kb/s]', id: 'Kbits', fn: SIPrefix('b/s', 1) },
      { name: 'メビバイト/秒 [MiB/s]', id: 'MiBs', fn: binaryPrefix('B/s', 2) },
      { name: 'メビミット/秒 [Mib/s]', id: 'Mibits', fn: binaryPrefix('b/s', 2) },
      { name: 'メガバイト/秒 [MB/s]', id: 'MBs', fn: SIPrefix('B/s', 2) },
      { name: 'メガビット/秒 [Mb/s]', id: 'Mbits', fn: SIPrefix('b/s', 2) },
      { name: 'ギビバイト/秒 [GiB/s]', id: 'GiBs', fn: binaryPrefix('B/s', 3) },
      { name: 'ギビビット/秒 [Gib/s]', id: 'Gibits', fn: binaryPrefix('b/s', 3) },
      { name: 'ギガバイト/秒 [GB/s]', id: 'GBs', fn: SIPrefix('B/s', 3) },
      { name: 'ギガビット/秒 [Gb/s]', id: 'Gbits', fn: SIPrefix('b/s', 3) },
      { name: 'テビバイト/秒 [TiB/s]', id: 'TiBs', fn: binaryPrefix('B/s', 4) },
      { name: 'テビビット/秒 [Tib/s]', id: 'Tibits', fn: binaryPrefix('b/s', 4) },
      { name: 'テラバイト/秒 [TB/s]', id: 'TBs', fn: SIPrefix('B/s', 4) },
      { name: 'テラビット/秒 [Tb/s]', id: 'Tbits', fn: SIPrefix('b/s', 4) },
      { name: 'ペビバイト/秒 [PiB/s]', id: 'PiBs', fn: binaryPrefix('B/s', 5) },
      { name: 'ぺビビット/秒 [Pib/s]', id: 'Pibits', fn: binaryPrefix('b/s', 5) },
      { name: 'ペタバイト/秒 [PB/s]', id: 'PBs', fn: SIPrefix('B/s', 5) },
      { name: 'ペタビット/秒 [Pb/s]', id: 'Pbits', fn: SIPrefix('b/s', 5) },
    ],
  },
  {
    name: '日付時刻',
    formats: [
      { name: 'ISO YYYY-MM-DD HH:mm:ss', id: 'dateTimeAsIso', fn: dateTimeAsIso },
      { name: 'ISO YYYY-MM-DD HH:mm:ss.SSS', id: 'dateTimeAsMs', fn: dateTimeAsMs },
      { name: 'ISO (No date if today)', id: 'dateTimeAsIsoNoDateIfToday', fn: dateTimeAsIsoNoDateIfToday },
      { name: 'ISO Msec (No date if today)', id: 'dateTimeAsMsNoDateIfToday', fn: dateTimeAsMsNoDateIfToday },
      { name: 'US MM/DD/YYYY h:mm:s a', id: 'dateTimeAsUS', fn: dateTimeAsUS },
      { name: 'US (No date if today)', id: 'dateTimeAsUSNoDateIfToday', fn: dateTimeAsUSNoDateIfToday },
      { name: 'ローカル YYYY/MM/DD HH:mm:ss', id: 'dateTimeAsLocal', fn: getDateTimeAsLocalFormat() },
      {
        name: 'ローカル (No date if today)',
        id: 'dateTimeAsLocalNoDateIfToday',
        fn: getDateTimeAsLocalFormatNoDateIfToday(),
      },
      { name: 'デフォルト YYYY-MM-DD HH:mm:ss', id: 'dateTimeAsSystem', fn: dateTimeSystemFormatter },
      { name: 'From Now', id: 'dateTimeFromNow', fn: dateTimeFromNow },
    ],
  },
  {
    name: 'エネルギー',
    formats: [
      { name: 'ワット (W)', id: 'watt', fn: SIPrefix('W') },
      { name: 'キロワット (kW)', id: 'kwatt', fn: SIPrefix('W', 1) },
      { name: 'メガワット (MW)', id: 'megwatt', fn: SIPrefix('W', 2) },
      { name: 'ギガワット (GW)', id: 'gwatt', fn: SIPrefix('W', 3) },
      { name: 'ミリワット (mW)', id: 'mwatt', fn: SIPrefix('W', -1) },
      { name: 'ワット/平方メートル (W/m²)', id: 'Wm2', fn: toFixedUnit('W/m²') },
      { name: 'ボルト-アンペア (VA)', id: 'voltamp', fn: SIPrefix('VA') },
      { name: 'キロボルト-アンペア (kVA)', id: 'kvoltamp', fn: SIPrefix('VA', 1) },
      { name: 'ボルト・アンペア無効電力 (var)', id: 'voltampreact', fn: SIPrefix('var') },
      { name: 'キロボルト・アンペア無効電力 (kvar)', id: 'kvoltampreact', fn: SIPrefix('var', 1) },
      { name: 'ワット時 (Wh)', id: 'watth', fn: SIPrefix('Wh') },
      { name: 'ワット時/キログラム (Wh/kg)', id: 'watthperkg', fn: SIPrefix('Wh/kg') },
      { name: 'キロワット時 (kWh)', id: 'kwatth', fn: SIPrefix('Wh', 1) },
      { name: 'キロワット分 (kWm)', id: 'kwattm', fn: SIPrefix('W-Min', 1) },
      { name: 'アンペア時 (Ah)', id: 'amph', fn: SIPrefix('Ah') },
      { name: 'キロアンペア時 (kAh)', id: 'kamph', fn: SIPrefix('Ah', 1) },
      { name: 'ミリアンペア時 (mAh)', id: 'mamph', fn: SIPrefix('Ah', -1) },
      { name: 'ジュール (J)', id: 'joule', fn: SIPrefix('J') },
      { name: 'エレクトロンボルト (eV)', id: 'ev', fn: SIPrefix('eV') },
      { name: 'アンペア (A)', id: 'amp', fn: SIPrefix('A') },
      { name: 'キロアンペア (kA)', id: 'kamp', fn: SIPrefix('A', 1) },
      { name: 'ミリアンペア (mA)', id: 'mamp', fn: SIPrefix('A', -1) },
      { name: 'ボルト (V)', id: 'volt', fn: SIPrefix('V') },
      { name: 'キロボルト (kV)', id: 'kvolt', fn: SIPrefix('V', 1) },
      { name: 'ミリボルト (mV)', id: 'mvolt', fn: SIPrefix('V', -1) },
      { name: 'デシベル-ミリワット (dBm)', id: 'dBm', fn: SIPrefix('dBm') },
      { name: 'オーム (Ω)', id: 'ohm', fn: SIPrefix('Ω') },
      { name: 'キロオーム (kΩ)', id: 'kohm', fn: SIPrefix('Ω', 1) },
      { name: 'メガオーム (MΩ)', id: 'Mohm', fn: SIPrefix('Ω', 2) },
      { name: 'ファラド (F)', id: 'farad', fn: SIPrefix('F') },
      { name: 'マイクロファラド (µF)', id: 'µfarad', fn: SIPrefix('F', -2) },
      { name: 'ナノファラド (nF)', id: 'nfarad', fn: SIPrefix('F', -3) },
      { name: 'ピコファラド (pF)', id: 'pfarad', fn: SIPrefix('F', -4) },
      { name: 'フェムトファラド (fF)', id: 'ffarad', fn: SIPrefix('F', -5) },
      { name: 'ヘンリー (H)', id: 'henry', fn: SIPrefix('H') },
      { name: 'ミリヘンリー (mH)', id: 'mhenry', fn: SIPrefix('H', -1) },
      { name: 'マイクロヘンリー (µH)', id: 'µhenry', fn: SIPrefix('H', -2) },
      { name: 'ルーメン (Lm)', id: 'lumens', fn: SIPrefix('Lm') },
    ],
  },
  {
    name: '流量',
    formats: [
      { name: 'ガロン/分 (gpm)', id: 'flowgpm', fn: toFixedUnit('gpm') },
      { name: '立方メートル/秒 (cms)', id: 'flowcms', fn: toFixedUnit('cms') },
      { name: '立方フィート/秒 (cfs)', id: 'flowcfs', fn: toFixedUnit('cfs') },
      { name: '立方フィート/分 (cfm)', id: 'flowcfm', fn: toFixedUnit('cfm') },
      { name: 'リットル/時 (L/h)', id: 'litreh', fn: toFixedUnit('L/h') },
      { name: 'リットル/分 (L/min)', id: 'flowlpm', fn: toFixedUnit('L/min') },
      { name: 'ミリリットル/分 (mL/min)', id: 'flowmlpm', fn: toFixedUnit('mL/min') },
      { name: 'ルクス (lx)', id: 'lux', fn: toFixedUnit('lux') },
    ],
  },
  {
    name: '力',
    formats: [
      { name: 'ニュートンメートル (Nm)', id: 'forceNm', fn: SIPrefix('Nm') },
      { name: 'キロニュートンメートル (kNm)', id: 'forcekNm', fn: SIPrefix('Nm', 1) },
      { name: 'ニュートン (N)', id: 'forceN', fn: SIPrefix('N') },
      { name: 'キロニュートン (kN)', id: 'forcekN', fn: SIPrefix('N', 1) },
    ],
  },
  {
    name: '採掘速度',
    formats: [
      { name: 'ハッシュ/秒 (H/s)', id: 'Hs', fn: SIPrefix('H/s') },
      { name: 'キロハッシュ/秒 (KH/s)', id: 'KHs', fn: SIPrefix('H/s', 1) },
      { name: 'メガハッシュ/秒 (MH/s)', id: 'MHs', fn: SIPrefix('H/s', 2) },
      { name: 'ギガハッシュ/秒 (GH/s)', id: 'GHs', fn: SIPrefix('H/s', 3) },
      { name: 'テラハッシュ/秒 (TH/s)', id: 'THs', fn: SIPrefix('H/s', 4) },
      { name: 'ペタハッシュ/秒 (PH/s)', id: 'PHs', fn: SIPrefix('H/s', 5) },
      { name: 'エクサハッシュ/秒 (EH/s)', id: 'EHs', fn: SIPrefix('H/s', 6) },
    ],
  },
  {
    name: '質量',
    formats: [
      { name: 'ミリグラム (mg)', id: 'massmg', fn: SIPrefix('g', -1) },
      { name: 'グラム (g)', id: 'massg', fn: SIPrefix('g') },
      { name: 'キログラム (kg)', id: 'masskg', fn: SIPrefix('g', 1) },
      { name: 'トン (t)', id: 'masst', fn: toFixedUnit('t') },
    ],
  },
  {
    name: '長さ',
    formats: [
      { name: 'ミリメートル (mm)', id: 'lengthmm', fn: SIPrefix('m', -1) },
      { name: 'フィート (ft)', id: 'lengthft', fn: toFixedUnit('ft') },
      { name: 'メートル (m)', id: 'lengthm', fn: SIPrefix('m') },
      { name: 'キロメートル (km)', id: 'lengthkm', fn: SIPrefix('m', 1) },
      { name: 'マイル (mi)', id: 'lengthmi', fn: toFixedUnit('mi') },
    ],
  },
  {
    name: '圧力',
    formats: [
      { name: 'ミリバール (mbar)', id: 'pressurembar', fn: SIPrefix('bar', -1) },
      { name: 'バール (bar)', id: 'pressurebar', fn: SIPrefix('bar') },
      { name: 'キロバール (kbar)', id: 'pressurekbar', fn: SIPrefix('bar', 1) },
      { name: 'パスカル (Pa)', id: 'pressurepa', fn: SIPrefix('Pa') },
      { name: 'ヘクトパスカル (hPa)', id: 'pressurehpa', fn: toFixedUnit('hPa') },
      { name: 'キロパスカル (kPa)', id: 'pressurekpa', fn: toFixedUnit('kPa') },
      { name: '水銀柱インチ (Hg)', id: 'pressurehg', fn: toFixedUnit('"Hg') },
      { name: '重量ポンド/平方インチ (psi)', id: 'pressurepsi', fn: scaledUnits(1000, ['psi', 'ksi', 'Mpsi']) },
    ],
  },
  {
    name: '放射線',
    formats: [
      { name: 'ベクレル (Bq)', id: 'radbq', fn: SIPrefix('Bq') },
      { name: 'キュリー (Ci)', id: 'radci', fn: SIPrefix('Ci') },
      { name: 'グレイ (Gy)', id: 'radgy', fn: SIPrefix('Gy') },
      { name: '吸収線量 (rad)', id: 'radrad', fn: SIPrefix('rad') },
      { name: 'シーベルト (Sv)', id: 'radsv', fn: SIPrefix('Sv') },
      { name: 'ミリシーベルト (mSv)', id: 'radmsv', fn: SIPrefix('Sv', -1) },
      { name: 'マイクロシーベルト (µSv)', id: 'radusv', fn: SIPrefix('Sv', -2) },
      { name: '線量当量 (rem)', id: 'radrem', fn: SIPrefix('rem') },
      { name: '照射線量 (C/kg)', id: 'radexpckg', fn: SIPrefix('C/kg') },
      { name: 'レントゲン (R)', id: 'radr', fn: SIPrefix('R') },
      { name: 'シーベルト/時 (Sv/h)', id: 'radsvh', fn: SIPrefix('Sv/h') },
      { name: 'ミリシーベルト/時 (mSv/h)', id: 'radmsvh', fn: SIPrefix('Sv/h', -1) },
      { name: 'マイクロシーベルト/時 (µSv/h)', id: 'radusvh', fn: SIPrefix('Sv/h', -2) },
    ],
  },
  {
    name: '回転速度',
    formats: [
      { name: '毎分回転数 (rpm)', id: 'rotrpm', fn: toFixedUnit('rpm') },
      { name: 'ヘルツ (Hz)', id: 'rothz', fn: SIPrefix('Hz') },
      { name: 'ラジアン/秒 (rad/s)', id: 'rotrads', fn: toFixedUnit('rad/s') },
      { name: '度/秒 (°/s)', id: 'rotdegs', fn: toFixedUnit('°/s') },
    ],
  },
  {
    name: '温度',
    formats: [
      { name: '摂氏 (°C)', id: 'celsius', fn: toFixedUnit('°C') },
      { name: '華氏 (°F)', id: 'fahrenheit', fn: toFixedUnit('°F') },
      { name: 'ケルビン (K)', id: 'kelvin', fn: toFixedUnit('K') },
    ],
  },
  {
    name: '時間',
    formats: [
      { name: 'ヘルツ (Hz)', id: 'hertz', fn: SIPrefix('Hz') },
      { name: 'ナノ秒 (ns)', id: 'ns', fn: toNanoSeconds },
      { name: 'マイクロ秒 (µs)', id: 'µs', fn: toMicroSeconds },
      { name: 'ミリ秒 (ms)', id: 'ms', fn: toMilliSeconds },
      { name: '秒 (s)', id: 's', fn: toSeconds },
      { name: '分 (m)', id: 'm', fn: toMinutes },
      { name: '時 (h)', id: 'h', fn: toHours },
      { name: '日 (d)', id: 'd', fn: toDays },
      { name: 'ミリ秒間 (ms)', id: 'dtdurationms', fn: toDurationInMilliseconds },
      { name: '秒間 (s)', id: 'dtdurations', fn: toDurationInSeconds },
      { name: '時間 (hh:mm:ss)', id: 'dthms', fn: toDurationInHoursMinutesSeconds },
      { name: '時間 (d hh:mm:ss)', id: 'dtdhms', fn: toDurationInDaysHoursMinutesSeconds },
      { name: 'タイムティック (s/100)', id: 'timeticks', fn: toTimeTicks },
      { name: 'クロック (ms)', id: 'clockms', fn: toClockMilliseconds },
      { name: 'クロック (s)', id: 'clocks', fn: toClockSeconds },
    ],
  },
  {
    name: 'スループット',
    formats: [
      { name: 'カウント/秒 (cps)', id: 'cps', fn: simpleCountUnit('cps') },
      { name: '秒間平均書込件数 (ops)', id: 'ops', fn: simpleCountUnit('ops') },
      { name: '秒間リクエスト件数 (rps)', id: 'reqps', fn: simpleCountUnit('reqps') },
      { name: '秒間読込回数 (rps)', id: 'rps', fn: simpleCountUnit('rps') },
      { name: '秒間書込回数 (wps)', id: 'wps', fn: simpleCountUnit('wps') },
      { name: '秒間平均I/O件数 (iops)', id: 'iops', fn: simpleCountUnit('iops') },
      { name: 'カウント/分 (cpm)', id: 'cpm', fn: simpleCountUnit('cpm') },
      { name: '分間平均書込件数 (opm)', id: 'opm', fn: simpleCountUnit('opm') },
      { name: '分間読込回数 (rpm)', id: 'rpm', fn: simpleCountUnit('rpm') },
      { name: '分間書込回数 (wpm)', id: 'wpm', fn: simpleCountUnit('wpm') },
    ],
  },
  {
    name: '速度',
    formats: [
      { name: 'メートル/秒 (m/s)', id: 'velocityms', fn: toFixedUnit('m/s') },
      { name: 'キロメートル/時 (km/h)', id: 'velocitykmh', fn: toFixedUnit('km/h') },
      { name: 'マイル/時 (mph)', id: 'velocitymph', fn: toFixedUnit('mph') },
      { name: 'ノット (kn)', id: 'velocityknot', fn: toFixedUnit('kn') },
    ],
  },
  {
    name: '容量',
    formats: [
      { name: 'ミリリットル (mL)', id: 'mlitre', fn: SIPrefix('L', -1) },
      { name: 'リットル (L)', id: 'litre', fn: SIPrefix('L') },
      { name: '立方メートル (m³)', id: 'm3', fn: toFixedUnit('m³') },
      { name: '立方メートル (Nm³)', id: 'Nm3', fn: toFixedUnit('Nm³') },
      { name: '立方デシメートル (dm³)', id: 'dm3', fn: toFixedUnit('dm³') },
      { name: 'ガロン (gal)', id: 'gallons', fn: toFixedUnit('gal') },
    ],
  },
];
