import React, { FC } from 'react';
import { css } from 'emotion';
import { Icon, IconName, useTheme } from '@grafana/ui';

export interface Props {
  child: any;
}

const DropDownChild: FC<Props> = (props) => {
  const { child } = props;
  const listItemClassName = child.divider ? 'divider' : '';
  const theme = useTheme();
  const iconClassName = css`
    margin-right: ${theme.spacing.sm};
  `;

  let childText = child.text;
  childText = childText.replace('Dashboard', 'ダッシュボード');
  childText = childText.replace('Folder', 'フォルダー');
  childText = childText.replace('Import', 'インポート');
  childText = childText.replace('Home', 'ホーム');
  childText = childText.replace('Manage', '管理');
  childText = childText.replace('Playlists', 'プレイリスト');
  childText = childText.replace('Snapshots', 'スナップショット');
  childText = childText.replace('Alert Rules', 'アラートルール');
  childText = childText.replace('Notification channels', '通知チャネル');
  childText = childText.replace('Data Sources', 'データソース');
  childText = childText.replace('Users', 'ユーザー');
  childText = childText.replace('Teams', 'チーム');
  childText = childText.replace('Plugins', 'プラグイン');
  childText = childText.replace('Preferences', '環境設定');
  childText = childText.replace('API Keys', 'APIキー');
  childText = childText.replace('Orgs', '組織');
  childText = childText.replace('Settings', '設定');
  childText = childText.replace('Stats', '統計情報');
  childText = childText.replace('Upgrade', 'アップグレード');
  //childText = childText.replace('Change Password', 'パスワード変更');
  //childText = childText.replace('Sign out', 'サインアウト');

  return (
    <li className={listItemClassName}>
      <a href={child.url}>
        {child.icon && <Icon name={child.icon as IconName} className={iconClassName} />}
        {childText}
      </a>
    </li>
  );
};

export default DropDownChild;
