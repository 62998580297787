import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { css } from 'emotion';
import { GrafanaTheme } from '@grafana/data';
import { selectors } from '@grafana/e2e-selectors';
import { useTheme } from '../../themes';
import { Icon } from '../Icon/Icon';
import { IconName } from '../../types/icon';
import { getColorsFromSeverity } from '../../utils/colors';

export type AlertVariant = 'success' | 'warning' | 'error' | 'info';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  /** On click handler for alert button, mostly used for dismissing the alert */
  onRemove?: (event: React.MouseEvent) => void;
  severity?: AlertVariant;
  children?: ReactNode;
  /** Custom component or text for alert button */
  buttonContent?: ReactNode | string;
  /** @deprecated */
  /** Deprecated use onRemove instead */
  onButtonClick?: (event: React.MouseEvent) => void;
  /** @deprecated */
  /** Deprecated use buttonContent instead */
  buttonText?: string;
}

function getIconFromSeverity(severity: AlertVariant): string {
  switch (severity) {
    case 'error':
    case 'warning':
      return 'exclamation-triangle';
    case 'info':
      return 'info-circle';
    case 'success':
      return 'check';
    default:
      return '';
  }
}

export const Alert: FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ title, buttonText, onButtonClick, onRemove, children, buttonContent, severity = 'error', ...restProps }, ref) => {
    const theme = useTheme();
    const styles = getStyles(theme, severity, !!buttonContent);

    let titleStr = title;
    titleStr = titleStr.replace('Invalid username or password', 'アカウントまたはパスワードが違います。');
    titleStr = titleStr.replace(
      'Failed to get admin stats from database',
      'データベースから管理統計を取得できません。'
    );
    titleStr = titleStr.replace('Dashboards config reloaded', 'ダッシュボード設定を再読込しました。');
    titleStr = titleStr.replace('Datasources config reloaded', 'データソース設定を再読込しました。');
    titleStr = titleStr.replace('Failed to reload plugins config', 'プラグイン設定の再読込に失敗しました。');
    titleStr = titleStr.replace('Plugins config reloaded', 'プラグイン設定を再読込しました。');
    titleStr = titleStr.replace('Notifications config reloaded', '通知設定を再読込しました。');
    titleStr = titleStr.replace(
      'Validation error, need specify either username or email',
      'アカウントまたはメールアドレスを指定する必要があります。'
    );
    titleStr = titleStr.replace('Password is missing or too short', 'パスワードがないか、短すぎます。');
    titleStr = titleStr.replace('failed to create user', 'ユーザーの作成に失敗しました。');
    titleStr = titleStr.replace('New password too short', '新しいパスワードが短すぎます。');
    titleStr = titleStr.replace('Could not read user from database', 'データベースからユーザー情報を取得できません。');
    titleStr = titleStr.replace('Could not encode password', 'パスワードの暗号化に失敗しました。');
    titleStr = titleStr.replace('Failed to update user password', 'パスワードの更新に失敗しました。');
    titleStr = titleStr.replace('User password updated', 'パスワードを更新しました。');
    titleStr = titleStr.replace('Failed to update user permissions', 'ユーザー権限の更新に失敗しました。');
    titleStr = titleStr.replace('User permissions updated', 'ユーザー権限を更新しました。');
    titleStr = titleStr.replace('Failed to delete user', 'ユーザーの削除に失敗しました。');
    titleStr = titleStr.replace('User deleted', 'ユーザーを削除しました。');
    titleStr = titleStr.replace('Could not disable external user', '外部ユーザーは無効化できません。');
    titleStr = titleStr.replace('Failed to disable user', 'ユーザーの無効化に失敗しました。');
    titleStr = titleStr.replace('User disabled', 'ユーザーを無効化しました。');
    titleStr = titleStr.replace('Could not enable external user', '外部ユーザーは有効化できません。');
    titleStr = titleStr.replace('Failed to enable user', 'ユーザーの有効化に失敗しました。');
    titleStr = titleStr.replace('User enabled', 'ユーザーを有効化しました。');
    titleStr = titleStr.replace('You cannot logout yourself', 'ログアウトできません。');
    titleStr = titleStr.replace('Missing query parameter dashboardId', 'クエリーパラメータ dashboardId がありません。');
    titleStr = titleStr.replace('Failed to fetch alert states', 'アラート統計の取得に失敗しました。');
    titleStr = titleStr.replace('List alerts failed', 'アラート表示に失敗しました。');
    titleStr = titleStr.replace(
      'The dashboard needs to be saved at least once before you can test an alert rule',
      'アラートルールのテスト前に、ダッシュボードを保存してください。'
    );
    titleStr = titleStr.replace('Access denied to datasource', 'データソースへのアクセスが拒否されました。');
    titleStr = titleStr.replace('Failed to test rule', 'ルールのテストに失敗しました。');
    titleStr = titleStr.replace('Failed to get alert notifications', 'アラート通知の取得に失敗しました。');
    titleStr = titleStr.replace('Alert notification not found', 'アラート通知が見つかりません。');
    titleStr = titleStr.replace('Failed to create alert notification', 'アラート通知の作成に失敗しました。');
    titleStr = titleStr.replace('Failed to update alert notification', 'アラート通知の更新に失敗しました。');
    titleStr = titleStr.replace('Failed to delete alert notification', 'アラート通知の削除に失敗しました。');
    titleStr = titleStr.replace('Failed to send alert notifications', 'アラート通知の送信に失敗しました。');
    titleStr = titleStr.replace('Test notification sent', 'テスト通知を送信しました。');
    titleStr = titleStr.replace('Get Alert failed', 'アラートの取得に失敗しました。');
    titleStr = titleStr.replace(
      'Error while checking permissions for Alert',
      'アラートの権限確認中にエラーが発生しました。'
    );
    titleStr = titleStr.replace(
      'Access denied to this dashboard and alert',
      'ダッシュボードとアラートへのアクセスが拒否されました。'
    );
    titleStr = titleStr.replace('Failed to pause alerts', 'アラートの一時停止に失敗しました。');
    titleStr = titleStr.replace('Failed to get annotations', '注釈の取得に失敗しました。');
    titleStr = titleStr.replace('Failed to save annotation', '注釈の保存に失敗しました。');
    titleStr = titleStr.replace('Failed to save Graphite annotation', 'Graphite 注釈の保存に失敗しました。');
    titleStr = titleStr.replace('Failed to update annotation', '注釈の更新に失敗しました。');
    titleStr = titleStr.replace('Annotation updated', '注釈を更新しました。');
    titleStr = titleStr.replace('Could not find annotation to update', '更新する注釈が見つかりません。');
    titleStr = titleStr.replace('Annotation patched', '注釈にパッチを適用しました。');
    titleStr = titleStr.replace('Failed to delete annotations', '注釈の削除に失敗しました。');
    titleStr = titleStr.replace('Annotations deleted', '注釈を削除しました。');
    titleStr = titleStr.replace('Annotation deleted', '注釈を削除しました。');
    titleStr = titleStr.replace('Annotation added', '注釈を追加しました。');
    titleStr = titleStr.replace('Failed to list api keys', 'APIキーの表示に失敗しました。');
    titleStr = titleStr.replace('Failed to delete API key', 'APIキーの削除に失敗しました。');
    titleStr = titleStr.replace('API key deleted', 'APIキーを削除しました。');
    titleStr = titleStr.replace('Invalid role specified', '無効なロールが指定されました。');
    titleStr = titleStr.replace(
      'Number of seconds before expiration should be set',
      '有効期限切れまでの秒数を設定してください。'
    );
    titleStr = titleStr.replace(
      'Number of seconds before expiration is greater than the global limit',
      '有効期限切れまでの秒数がグローバル制限を超えています。'
    );
    titleStr = titleStr.replace('Generating API key failed', 'APIキーの生成に失敗しました。');
    titleStr = titleStr.replace('Failed to add API Key', 'APIキーの追加に失敗しました。');
    titleStr = titleStr.replace(
      'Error while checking dashboard permissions',
      'ダッシュボード権限の確認中にエラーが発生しました。'
    );
    titleStr = titleStr.replace('Access denied to this dashboard', 'このダッシュボードへのアクセスは拒否されました。');
    titleStr = titleStr.replace(
      'Error while loading dashboard, dashboard data is invalid',
      'ダッシュボードの読込中にエラーが発生しました。ダッシュボードデータが無効です。'
    );
    titleStr = titleStr.replace(
      'Error while checking if dashboard was starred by user',
      'ダッシュボードのお気に入り確認中にエラーが発生しました。'
    );
    titleStr = titleStr.replace('Dashboard folder could not be read', 'ダッシュボード・フォルダーが読込できません。');
    titleStr = titleStr.replace(
      'Error while checking if dashboard is provisioned',
      'ダッシュボードのプロビジョニング確認中にエラーが発生しました。'
    );
    titleStr = titleStr.replace(
      'Error while loading library panels',
      'ライブラリ・パネルの読込中にエラーが発生しました。'
    );
    titleStr = titleStr.replace(
      'Failed to retrieve dashboards by slug',
      'スラッグによるダッシュボード取得に失敗しました。'
    );
    titleStr = titleStr.replace('failed to get quota', 'クォータの取得に失敗しました。');
    titleStr = titleStr.replace('Quota reached', 'クォータに達しました。');
    titleStr = titleStr.replace(
      'Error while cleaning library panels',
      'ライブラリ・パネルのクリーニング中にエラーが発生しました。'
    );
    titleStr = titleStr.replace(
      'Error while connecting library panels',
      'ライブラリ・パネルの接続中にエラーが発生しました。'
    );
    titleStr = titleStr.replace('Failed to save dashboard', 'ダッシュボードの保存に失敗しました。');
    titleStr = titleStr.replace('Failed to get preferences', '環境設定の取得に失敗しました。');
    titleStr = titleStr.replace('Failed to load home dashboard', 'ホームダッシュボードの取得に失敗しました。');
    titleStr = titleStr.replace(
      'No versions found for dashboardId',
      'ダッシュボード版数が見つかりません。ダッシュボードID:'
    );
    titleStr = titleStr.replace('Dashboard version not found', 'ダッシュボード版数が見つかりません。 ');
    titleStr = titleStr.replace('Dashboard version', 'ダッシュボード版数');
    titleStr = titleStr.replace('not found for dashboardId', 'が見つかりません。ダッシュボードID:');
    titleStr = titleStr.replace('Unable to compute diff', '差分を計算できません.');
    titleStr = titleStr.replace('Failed to get dashboard permissions', 'ダッシュボード権限の取得に失敗しました。');
    titleStr = titleStr.replace(
      'Error while retrieving hidden permissions',
      '非表示権限の取得中にエラーが発生しました。'
    );
    titleStr = titleStr.replace(
      'Cannot remove own admin permission for a folder',
      'フォルダー独自の管理者権限を削除できません。'
    );
    titleStr = titleStr.replace('Failed to create permission', '権限の作成に失敗しました。');
    titleStr = titleStr.replace('Dashboard permissions updated', 'ダッシュボード権限を更新しました。');
    titleStr = titleStr.replace('Failed to get dashboard snapshot', 'スナップショットの取得に失敗しました。');
    titleStr = titleStr.replace('Dashboard snapshot not found', 'スナップショットが見つかりません。');
    titleStr = titleStr.replace(
      'Failed to get dashboard data for dashboard snapshot',
      'スナップショットのダッシュボードデータの取得に失敗しました。'
    );
    titleStr = titleStr.replace('Failed to delete external dashboard', '外部ダッシュボードの削除に失敗しました。');
    titleStr = titleStr.replace('Failed to delete dashboard snapshot', 'スナップショットの削除に失敗しました。');
    titleStr = titleStr.replace(
      'Error while checking permissions for snapshot',
      'スナップショットの権限確認中にエラーが発生ました。'
    );
    titleStr = titleStr.replace('Access denied to this snapshot', 'スナップショットへのアクセスが拒否されました。');
    titleStr = titleStr.replace('Search failed', '検索に失敗しました。');
    titleStr = titleStr.replace('Failed to query datasources', 'データソースのクエリーに失敗しました。');
    titleStr = titleStr.replace('Data source not found', 'データソースが見つかりません。');
    titleStr = titleStr.replace('Datasource id is missing', 'データソースIDがありません。');
    titleStr = titleStr.replace('Missing valid datasource id', '有効なデータソースIDがありません。');
    titleStr = titleStr.replace('Failed to delete datasource', 'データソースの削除に失敗しました。');
    titleStr = titleStr.replace('Cannot delete read-only data source', '読取専用のデータソースは削除できません。');
    titleStr = titleStr.replace('Data source deleted', 'データソースを削除しました。');
    titleStr = titleStr.replace('Missing datasource uid', 'データソースuidがありません。');
    titleStr = titleStr.replace('Missing valid datasource name', '有効なデータソース名がありません。');
    titleStr = titleStr.replace('Failed to add datasource', 'データソースの追加に失敗しました。');
    titleStr = titleStr.replace('Failed to update datasource', 'データソースの更新に失敗しました。');
    titleStr = titleStr.replace(
      'Datasource has already been updated by someone else. Please reload and try again',
      'データソースは既に他利用者によって更新されています。リロードして再試行してください。'
    );
    titleStr = titleStr.replace('Unable to load datasource metadata', 'データソースのメタデータを読み込みできません。');
    titleStr = titleStr.replace('Unable to find datasource plugin', 'データソース・プラグインが見つかりません。');
    titleStr = titleStr.replace('Unable to get datasource model', 'データソース・モデルが取得できません。');
    titleStr = titleStr.replace(
      'Failed to unmarshal detailed response from backend plugin',
      'バックエンドプラグインからの詳細応答の非直列化に失敗しました。'
    );
    titleStr = titleStr.replace(
      'Folder could not be deleted because it contains linked library panels',
      'リンクされたライブラリ・パネルを含むため、フォルダーを削除できません。'
    );
    titleStr = titleStr.replace('Folder API error', 'フォルダーAPIエラーです。');
    titleStr = titleStr.replace('Failed to get folder permissions', 'フォルダー権限の取得に失敗しました。');
    titleStr = titleStr.replace('Error while checking folder permissions', 'フォルダー権限の確認に失敗しました。');
    titleStr = titleStr.replace('Logged in', 'ログイン成功');
    titleStr = titleStr.replace('Unauthorized', 'ログイン認証してください。');
    titleStr = titleStr.replace('No queries found in query', 'クエリーが見つかりません。');
    titleStr = titleStr.replace('Query missing data source ID', 'クエリーにデータソースIDがありません。');
    titleStr = titleStr.replace('Metric request error', 'メトリック要求エラー');
    titleStr = titleStr.replace('expression request error', '式要求エラー');
    titleStr = titleStr.replace('Access denied to data source', 'データソースへのアクセスが拒否されました。');
    titleStr = titleStr.replace('Invalid data source ID', 'データソースIDが不正です。');
    titleStr = titleStr.replace('Unable to load data source metadata', 'データソースのメタデータが読み込めません。');
    titleStr = titleStr.replace('Query missing datasourceId', 'クエリーにデータソースIDがありません。');
    titleStr = titleStr.replace('Failed to insert test data', 'テストデータの挿入に失敗しました。');
    titleStr = titleStr.replace('Organization not found', '組織が見つかりません。');
    titleStr = titleStr.replace('Failed to get organization', '組織の取得に失敗しました。');
    titleStr = titleStr.replace('Organization name taken', '組織名競合エラー');
    titleStr = titleStr.replace('Failed to create organization', '組織の作成に失敗しました。');
    titleStr = titleStr.replace('Failed to update organization', '組織の更新に失敗しました。');
    titleStr = titleStr.replace('Organization updated', '組織を更新しました。');
    titleStr = titleStr.replace('Failed to update org address', '組織アドレスの更新に失敗しました。');
    titleStr = titleStr.replace('Address updated', '組織アドレスを更新しました。');
    titleStr = titleStr.replace(
      'Failed to delete organization. ID not found',
      '組織の削除に失敗しました。IDが見つかりません。'
    );
    titleStr = titleStr.replace('Organization deleted', '組織を削除しました。');
    titleStr = titleStr.replace('Failed to search orgs', '組織の検索に失敗しました。');
    titleStr = titleStr.replace('Failed to get invites from db', 'DBからの招待の取得に失敗しました。');
    titleStr = titleStr.replace(
      'Failed to query db for existing user check',
      '既存ユーザーチェックでデータベースにクエリー実行に失敗しました。'
    );
    titleStr = titleStr.replace('Cannot invite when login is disabled.', 'ログインが無効の場合は招待できません。');
    titleStr = titleStr.replace('Could not generate random string', 'ランダムな文字列が生成できません。');
    titleStr = titleStr.replace('Failed to save invite to database', '招待のデータベース保存に失敗しました。');
    titleStr = titleStr.replace('Failed to send email invited_to_org', '組織への招待メールの送信に失敗しました。');
    titleStr = titleStr.replace('Failed to send email invite', '招待メールの送信に失敗しました。');
    titleStr = titleStr.replace(
      'Failed to update invite with email sent info',
      'メール送信した情報で招待を更新できませんでした。'
    );
    titleStr = titleStr.replace('Error while trying to create org user', '組織ユーザー作成中にエラーが発生しました。');
    titleStr = titleStr.replace('Invite revoked', '招待を取り消しました。');
    titleStr = titleStr.replace('Invite not found', '招待が見つかりません。');
    titleStr = titleStr.replace('Failed to get invite', '招待の取得に失敗しました。');
    titleStr = titleStr.replace('failed to publish event', 'イベントの公開に失敗しました。');
    titleStr = titleStr.replace('failed to accept invite', '招待の受入に失敗しました。');
    titleStr = titleStr.replace('User not found', 'ユーザーが見つかりません。');
    titleStr = titleStr.replace('Could not add user to organization', '組織にユーザーを追加できませんでした。');
    titleStr = titleStr.replace(
      'Failed to get users for current organization',
      '現組織からユーザーの取得に失敗しました。'
    );
    titleStr = titleStr.replace('Permission denied', '権限がありません。');
    titleStr = titleStr.replace('Failed to get users for organization', '組織からユーザーの取得に失敗しました。');
    titleStr = titleStr.replace(
      'Cannot change role so that there is no organization admin left',
      '組織管理者が不在になるロール変更はできません。'
    );
    titleStr = titleStr.replace('Failed update org user', '組織ユーザーの更新に失敗しました。');
    titleStr = titleStr.replace('Organization user updated', '組織ユーザーを更新しました。');
    titleStr = titleStr.replace('Cannot remove last organization admin', '最後の組織管理者は削除できません。');
    titleStr = titleStr.replace('Failed to remove user from organization', '組織からのユーザー削除に失敗しました。');
    titleStr = titleStr.replace(
      'Not allowed to reset password when login form is disabled',
      'ログイン無効になっている場合、パスワードをリセットすることはできません。'
    );
    titleStr = titleStr.replace('Email sent', 'メールを送信しました。');
    titleStr = titleStr.replace('Failed to send email', 'メール送信に失敗しました。');
    titleStr = titleStr.replace(
      'Invalid or expired reset password code',
      '無効または期限切れのパスワードリセットコードです。'
    );
    titleStr = titleStr.replace(
      'Unknown error validating email code',
      'メールコードの検査で不明なエラーが発生しました。'
    );
    titleStr = titleStr.replace('Passwords do not match', 'パスワードが一致しません。');
    titleStr = titleStr.replace('Failed to encode password', 'パスワードの暗号化に失敗しました。');
    titleStr = titleStr.replace('Failed to change user password', 'パスワードの変更に失敗しました。');
    titleStr = titleStr.replace('User password changed', 'パスワードを更新しました。');
    titleStr = titleStr.replace('Playlist not found', 'プレイリストが見つかりません。');
    titleStr = titleStr.replace('Could not load playlist items', 'プレイリスト・アイテムが読み込めません。');
    titleStr = titleStr.replace('Could not load dashboards', 'ダッシュボードが読み込めません。');
    titleStr = titleStr.replace('Failed to delete playlist', 'プレイリストの削除に失敗しました。');
    titleStr = titleStr.replace('Failed to create playlist', 'プレイリストの作成に失敗しました。');
    titleStr = titleStr.replace('Failed to save playlist', 'プレイリストの保存に失敗しました。');
    titleStr = titleStr.replace('Failed to get list of plugins', 'プラグインリストの取得に失敗しました。');
    titleStr = titleStr.replace(
      'Plugin not found, no installed plugin with that id',
      'プラグインが見つかりません。指定IDのプラグインがインストールされていません。'
    );
    titleStr = titleStr.replace('Failed to get login settings', 'ログイン設定の取得に失敗しました。');
    titleStr = titleStr.replace('Plugin not installed.', 'プラグインがインストールされていません。');
    titleStr = titleStr.replace('Failed to update plugin setting', 'プラグイン設定の更新に失敗しました。');
    titleStr = titleStr.replace('Plugin settings updated', 'プラグイン設定を更新しました。');
    titleStr = titleStr.replace('Failed to get plugin dashboards', 'プラグインダッシュボードの取得に失敗しました。');
    titleStr = titleStr.replace('Could not get markdown file', 'MarkDownファイルが取得できません。');
    titleStr = titleStr.replace('Dashboard must be set', 'ダッシュボードを設定してください。');
    titleStr = titleStr.replace('Plugin not found', 'プラグインが見つかりません。');
    titleStr = titleStr.replace('Failed to get plugin settings', 'プラグイン設定の取得に失敗しました。');
    titleStr = titleStr.replace('Plugin health check failed', 'プラグイン状態確認に失敗しました。');
    titleStr = titleStr.replace('Plugin unavailable', 'プラグインが無効です。');
    titleStr = titleStr.replace('Plugin request failed', 'プラグイン要求に失敗しました。');
    titleStr = titleStr.replace('Failed to set home dashboard', 'ホームダッシュボードの設定に失敗しました。');
    titleStr = titleStr.replace('Home dashboard set', 'ホームダッシュボードを設定しました。');
    titleStr = titleStr.replace('Failed to save preferences', '環境設定の保存に失敗しました。');
    titleStr = titleStr.replace('Preferences updated', '環境設定を更新しました。');
    titleStr = titleStr.replace('Quotas not enabled', 'クォータが無効です。');
    titleStr = titleStr.replace('Failed to get org quotas', '組織クォータの取得に失敗しました。');
    titleStr = titleStr.replace('Invalid quota target', 'クォータの対象が不正です。');
    titleStr = titleStr.replace('Failed to update org quotas', '組織クォータの更新に失敗しました。');
    titleStr = titleStr.replace('Organization quota updated', '組織クォータを更新しました。');
    titleStr = titleStr.replace(
      'Limit is above maximum allowed (5000), use page parameter to access hits beyond limit',
      '制限が最大許容値(5000)を超えています。'
    );
    titleStr = titleStr.replace('Path should be relative', 'パスは相対パスにしてください。');
    titleStr = titleStr.replace('Failed to create short URL', 'ショートURLの作成に失敗しました。');
    titleStr = titleStr.replace('User signup is disabled', 'ユーザーのサインアップが無効です。');
    titleStr = titleStr.replace(
      'User with same email address already exists',
      '同じメールアドレスのユーザーが既に存在します。'
    );
    titleStr = titleStr.replace('Failed to generate random string', 'ランダムな文字列の生成に失敗しました。');
    titleStr = titleStr.replace('Failed to create signup', 'サインアップの生成に失敗しました。');
    titleStr = titleStr.replace(
      'Failed to query database for invites',
      'データベースへの招待のクエリーに失敗しました。'
    );
    titleStr = titleStr.replace('failed to login user', 'ログインに失敗しました。');
    titleStr = titleStr.replace('Missing dashboard id', 'ダッシュボードIDが見つかりません。');
    titleStr = titleStr.replace('Failed to star dashboard', 'ダッシュボードのお気に入り設定に失敗しました。');
    titleStr = titleStr.replace('Dashboard starred!', 'ダッシュボードをお気に入りに設定しました。');
    titleStr = titleStr.replace('Failed to unstar dashboard', 'ダッシュボードのお気に入り解除に失敗しました。');
    titleStr = titleStr.replace('Dashboard unstarred', 'ダッシュボードをお気に入りを解除しました。');
    titleStr = titleStr.replace('Not allowed to create team.', 'チームの生成が許可されていません。');
    titleStr = titleStr.replace('Team name taken', 'チーム名競合エラー');
    titleStr = titleStr.replace('Not allowed to view team preferences.', 'チーム設定の表示は許可されていません。');
    titleStr = titleStr.replace('Not allowed to update team preferences.', 'チーム設定の更新は許可されていません。');
    titleStr = titleStr.replace('Failed to get Team Members', 'チームメンバーの取得に失敗しました。');
    titleStr = titleStr.replace('Not allowed to add team member', 'チームメンバーの追加は許可されていません。');
    titleStr = titleStr.replace('User is already added to this team', 'ユーザーは既にこのチームに追加されています。');
    titleStr = titleStr.replace('Failed to add Member to Team', 'チームへのユーザー追加に失敗しました。');
    titleStr = titleStr.replace('Not allowed to update team member', 'チームメンバーの更新は許可されていません。');
    titleStr = titleStr.replace('Team member not found.', 'チームメンバーが見つかりません。');
    titleStr = titleStr.replace('Failed to update team member.', 'チームメンバーの更新に失敗しました。');
    titleStr = titleStr.replace('Team member updated', 'チームメンバーを更新しました。');
    titleStr = titleStr.replace('Not allowed to remove team member', 'チームメンバーの削除は許可されていません。');
    titleStr = titleStr.replace('Team member not found', 'チームメンバーが見つかりません。');
    titleStr = titleStr.replace('Failed to remove Member from Team', 'チームメンバーの削除に失敗しました。');
    titleStr = titleStr.replace('Team Member removed', 'チームメンバーを削除しました。');
    titleStr = titleStr.replace('Failed to create Team', 'チームの生成に失敗しました。');
    titleStr = titleStr.replace('Not allowed to update team', 'チームの更新が許可されていません。');
    titleStr = titleStr.replace('Failed to update Team', 'チームの更新に失敗しました。');
    titleStr = titleStr.replace('Team updated', 'チームを更新しました。');
    titleStr = titleStr.replace('Not allowed to delete team', 'チームの削除が許可されていません。');
    titleStr = titleStr.replace(
      'Failed to delete Team. ID not found',
      'チームの削除に失敗しました。IDが見つかりません。'
    );
    titleStr = titleStr.replace('Failed to delete Team', 'チームの削除に失敗しました。');
    titleStr = titleStr.replace('Team deleted', 'チームを削除しました。');
    titleStr = titleStr.replace('Failed to search Teams', 'チームの検索に失敗しました。');
    titleStr = titleStr.replace('Team not found', 'チームが見つかりません。');
    titleStr = titleStr.replace('Failed to get Team', 'チームの取得に失敗しました。');
    titleStr = titleStr.replace(
      'Not allowed to change email when auth proxy is using email property',
      '認証プロキシがメールプロパティを使用している場合、メールの変更は許可されていません。'
    );
    titleStr = titleStr.replace(
      'Not allowed to change username when auth proxy is using username property',
      '認証プロキシがユーザー名プロパティを使用している場合、ユーザー名の変更は許可されていません。'
    );
    titleStr = titleStr.replace('Not a valid organization', '組織が無効です。');
    titleStr = titleStr.replace('Failed to change active organization', '組織の有効化に失敗しました。');
    titleStr = titleStr.replace('Active organization changed', '組織を更新しました。');
    titleStr = titleStr.replace(
      'Validation error, need to specify either username or email',
      'アカウントまたはメールアドレスを指定してください。'
    );
    titleStr = titleStr.replace('Failed to update user', 'ユーザーの更新に失敗しました。');
    titleStr = titleStr.replace('User updated', 'ユーザーを更新しました。');
    titleStr = titleStr.replace('Failed to get user organizations', 'ユーザー組織の取得に失敗しました。');
    titleStr = titleStr.replace(
      'Not allowed to change password when LDAP or Auth Proxy is enabled',
      'LDAPまたは認証プロキシが有効の場合、パスワードを変更することはできません。'
    );
    titleStr = titleStr.replace('Invalid old password', '現在のパスワードが不正です。');
    titleStr = titleStr.replace('New password is too short', '新しいパスワードが短すぎます。');
    titleStr = titleStr.replace('Failed to fetch users', 'ユーザーの取得に失敗しました。');
    titleStr = titleStr.replace('Failed to update help flag', 'ヘルプフラグの更新に失敗しました。');
    titleStr = titleStr.replace('Failed to logout user', 'ログアウトに失敗しました。');
    titleStr = titleStr.replace('Failed to get user auth tokens', 'ユーザー認証トークンの取得に失敗しました。');
    titleStr = titleStr.replace('User auth token not found', 'ユーザー認証トークンが見つかりません。');
    titleStr = titleStr.replace('Failed to get user auth token', 'ユーザー認証トークンの取得に失敗しました。');
    titleStr = titleStr.replace(
      'Cannot revoke active user auth token',
      'アクティブユーザーの認証トークン取消はできません。'
    );
    titleStr = titleStr.replace('Failed to revoke user auth token', 'ユーザー認証トークンの取消に失敗しました。');
    titleStr = titleStr.replace('Failed to get user', 'ユーザーの取得に失敗しました。');
    titleStr = titleStr.replace('Server error', 'サーバーエラーです。');
    titleStr = titleStr.replace('Library panel connected', 'ライブラリ・パネルに接続しました。');
    titleStr = titleStr.replace('Library panel deleted', 'ライブラリ・パネルを削除しました。');
    titleStr = titleStr.replace('Library panel disconnected', 'ライブラリ・パネルから切断しました。');
    titleStr = titleStr.replace('invalid condition', '状態が不正です。');
    titleStr = titleStr.replace('Failed to evaluate conditions', '条件の評価に失敗しました。');
    titleStr = titleStr.replace('Failed to encode result dataframes', '結果データフレームのエンコードに失敗しました。');
    titleStr = titleStr.replace('Failed to load alert definition conditions', 'アラート定義条件の読込に失敗しました。');
    titleStr = titleStr.replace('Failed to evaluate alert', 'アラートの評価に失敗しました。');
    titleStr = titleStr.replace(
      'Failed to instantiate Dataframes from the decoded frames',
      'デコードされたフレームからのデータフレームのインスタンス化に失敗しました。'
    );
    titleStr = titleStr.replace('Failed to get alert definition', 'アラート定義の取得に失敗しました。');
    titleStr = titleStr.replace('Failed to delete alert definition', 'アラート定義の削除に失敗しました。');
    titleStr = titleStr.replace('Alert definition deleted', 'アラート定義を削除しました。');
    titleStr = titleStr.replace('Failed to update alert definition', 'アラート定義の更新に失敗しました。');
    titleStr = titleStr.replace('Failed to create alert definition', 'アラート定義の生成に失敗しました。');
    titleStr = titleStr.replace('Failed to list alert definitions', 'アラート定義の表示に失敗しました。');
    titleStr = titleStr.replace('Failed to pause scheduler', 'スケジューラの一時停止に失敗しました。');
    titleStr = titleStr.replace('Failed to unpause scheduler', 'スケジューラの再開に失敗しました。');
    titleStr = titleStr.replace('Failed to pause alert definition', 'アラート定義の一時停止に失敗しました。');
    titleStr = titleStr.replace('Failed to unpause alert definition', 'アラート定義の再開に失敗しました。');
    titleStr = titleStr.replace('Failed to list alert instances', 'アラートインスタンスの表示に失敗しました。');
    titleStr = titleStr.replace('Failed to delete dashboard', 'ダッシュボードの削除に失敗しました。');
    titleStr = titleStr.replace('Datasource added', 'データソースを追加しました。');
    titleStr = titleStr.replace('Datasource updated', 'データソースを更新しました。');
    titleStr = titleStr.replace('Folder permissions updated', 'フォルダーアクセス権限を更新しました。');
    titleStr = titleStr.replace('Organization created', '組織を作成しました。');
    titleStr = titleStr.replace('User created and logged in', 'ユーザーを作成しログインに成功しました。');
    titleStr = titleStr.replace(
      'User is already member of this organization',
      'ユーザーは既にこの組織に追加されています。'
    );
    titleStr = titleStr.replace('User added to organization', '組織にユーザーを追加しました。');
    titleStr = titleStr.replace('User sign up completed successfully', 'ユーザーサインアップが成功しました。');
    titleStr = titleStr.replace('Team created', 'チームを作成しました。');
    titleStr = titleStr.replace('Member added to Team', 'チームにメンバーを追加しました。');
    titleStr = titleStr.replace('User logged out', 'ユーザーがログアウトしました。');
    titleStr = titleStr.replace('User auth token revoked', 'ユーザー認証トークンを取消しました。');
    titleStr = titleStr.replace('User created', 'ユーザーを作成しました。');
    titleStr = titleStr.replace('Access denied', 'アクセスが拒否されました。');
    titleStr = titleStr.replace('Not found', '見つかりません。');
    titleStr = titleStr.replace('Unexpected error', '不明なエラーが発生しました。');
    titleStr = titleStr.replace(/^User with email .* or username .* already exists$/, function (match) {
      let rtn = match.replace('User with email', 'メールアドレス');
      rtn = rtn.replace('or username', 'またはアカウント');
      rtn = rtn.replace('already exists', 'が既に使われています。');
      return rtn;
    });
    titleStr = titleStr.replace(/^Folder .* deleted$/, function (match) {
      let rtn = 'フォルダー' + match.replace(/^Folder/, '').replace(/ deleted$$/, '') + 'を削除しました。';
      return rtn;
    });
    titleStr = titleStr.replace('Failed to fetch dashboard', 'ダッシュボードの取得に失敗しました。');

    return (
      <div ref={ref} className={styles.alert} aria-label={selectors.components.Alert.alert(severity)} {...restProps}>
        <div className={styles.icon}>
          <Icon size="xl" name={getIconFromSeverity(severity) as IconName} />
        </div>
        <div className={styles.body}>
          <div className={styles.title}>{titleStr}</div>
          {children && <div>{children}</div>}
        </div>
        {/* If onRemove is specified, giving preference to onRemove */}
        {onRemove ? (
          <button type="button" className={styles.close} onClick={onRemove}>
            {buttonContent || <Icon name="times" size="lg" />}
          </button>
        ) : onButtonClick ? (
          <button type="button" className="btn btn-outline-danger" onClick={onButtonClick}>
            {buttonText}
          </button>
        ) : null}
      </div>
    );
  }
);

Alert.displayName = 'Alert';

const getStyles = (theme: GrafanaTheme, severity: AlertVariant, outline: boolean) => {
  const { white } = theme.palette;
  const severityColors = getColorsFromSeverity(severity, theme);
  const background = css`
    background: linear-gradient(90deg, ${severityColors[0]}, ${severityColors[0]});
  `;

  return {
    alert: css`
      flex-grow: 1;
      padding: 15px 20px;
      margin-bottom: ${theme.spacing.xs};
      position: relative;
      color: ${white};
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
      border-radius: ${theme.border.radius.md};
      display: flex;
      flex-direction: row;
      align-items: center;
      ${background}
    `,
    icon: css`
      padding: 0 ${theme.spacing.md} 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
    `,
    title: css`
      font-weight: ${theme.typography.weight.semibold};
    `,
    body: css`
      flex-grow: 1;
      margin: 0 ${theme.spacing.md} 0 0;
      overflow-wrap: break-word;
      word-break: break-word;

      a {
        color: ${white};
        text-decoration: underline;
      }
    `,
    close: css`
      background: none;
      display: flex;
      align-items: center;
      border: ${outline ? `1px solid ${white}` : 'none'};
      border-radius: ${theme.border.radius.sm};
    `,
  };
};
